const ArticleList = defineAsyncComponent(() => import("~/components/Content/Carousel/ArticleList.vue"));
const HeroTabulatedBanner = defineAsyncComponent(() => import("~/components//Content/Banner/HeroTabulatedBanner.vue"));
const FeaturedCards = defineAsyncComponent(() => import("~/components/Content/Carousel/FeaturedCardsCarousel.vue"));
const CustomerTestimonials = defineAsyncComponent(() => import("~/components/Content/CustomerTestimonials.vue"));
const LogoCarousel = defineAsyncComponent(() => import("~/components//Content/LogoCarousel.vue"));
const Gallery = defineAsyncComponent(() => import("~/components/Content/Gallery.vue"));
const Video = defineAsyncComponent(() => import("~/components/Content/Video.vue"));
const Header = defineAsyncComponent(() => import("~/components/Global/Header/Header.vue"));

const BlogHero = defineAsyncComponent(() => import("~/components/Content/BlogHero.vue"));
const ChevronBanner = defineAsyncComponent(() => import("~/components/Content/Banner/ChevronBanner.vue"));
const CircledItemsList = defineAsyncComponent(() => import("~/components/Content/CircledItems/CircledItemsList.vue"));
const CreateAccount = defineAsyncComponent(() => import("~/components/Page/Login/CreateAccount.vue"));
const DepartmentRolesList = defineAsyncComponent(() => import("~/components/Content/DepartmentRolesList.vue"));
const DoubleBanner = defineAsyncComponent(() => import("~/components/Content/Banner/DoubleBanner.vue"));
const EditorialBlock = defineAsyncComponent(() => import("~/components/Content/EditorialBlock.vue"));
const EditorialCards = defineAsyncComponent(() => import("~/components/Content/EditorialCards.vue"));
const FeaturedArticles = defineAsyncComponent(() => import("~/components/Content/FeaturedArticles.vue"));
const Footer = defineAsyncComponent(() => import("~/components/Global/Footer/Footer.vue"));
const FooterSocialLinks = defineAsyncComponent(() => import("~/components/Global/Footer/FooterSocialLinks.vue"));
const FooterTermsMenu = defineAsyncComponent(() => import("~/components/Global/Footer/FooterTermsMenu.vue"));
const HeaderBar = defineAsyncComponent(() => import("~/components/Content/USP/HeaderBar.vue"));
const HelpTitleText = defineAsyncComponent(() => import("~/components/Content/HelpTitleText.vue"));
const IframeContainer = defineAsyncComponent(() => import("~/components/Content/IframeContainer.vue"));
const ImageContainer = defineAsyncComponent(() => import("~/components/Content/ImageContainer.vue"));
const ImageWithLinkSection = defineAsyncComponent(() => import("~/components/Content/ImageWithLinkSection.vue"));
const ImageWithTextSection = defineAsyncComponent(() => import("~/components/Content/ImageWithTextSection.vue"));
const KitchenList = defineAsyncComponent(() => import("~/components/Content/KitchenList.vue"));
const LoginInfoPanel = defineAsyncComponent(() => import("~/components/Page/Login/LoginInfoPanel.vue"));
const MarketingBanner = defineAsyncComponent(() => import("~/components/Content/Banner/MarketingBanner.vue"));
const Menu = defineAsyncComponent(() => import("~/components/Global/Menu/Menu.vue"));
const PageBlocks = defineAsyncComponent(() => import("~/components/Content/USP/PageBlocks.vue"));
const PatternList = defineAsyncComponent(() => import("~/components/Content/PatternList.vue"));
const PromotionalTiles = defineAsyncComponent(() => import("~/components/Content/PromotionalTiles.vue"));
const HeaderGuestMenu = defineAsyncComponent(() => import("~/components/Global/Header/HeaderGuestMenu.vue"));

import SearchResults from "~/components/Page/Search/SearchResults.vue";

import axios from "axios";
import {
  initialize as initializeSdk,
  type Configuration,
  type Page,
  TYPE_CONTAINER_ITEM_UNDEFINED,
} from "@bloomreach/spa-sdk";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const link = (data: any, page: any) => {
  if (data?.internalLink) {
    return {
      type: "internal",
      href: data.internalLink || "",
    };
  }
  if (data?.link) {
    const l = data.link.startsWith("http") ? data.link : "//" + data.link;
    return {
      href: l.replace("///", "/") || "",
      target: "_blank",
    };
  }
  if (data?.cmslink?.$ref) {
    return {
      type: "internal",
      href: page.getContent(data.cmslink.$ref).model?.links?.site?.href || "",
    };
  }
  return {
    type: "internal",
    href: "",
  };
};

export const componentMapping = {
  ArticleList: ArticleList,
  BlogHero: BlogHero,
  ChevronBanner: ChevronBanner,
  ChevronImageWithRotatingText: CustomerTestimonials,
  CircleListComponent: CircledItemsList,
  CreateAccountRationale: CreateAccount,
  DepartmentRolesList: DepartmentRolesList,
  DoubleBanner: DoubleBanner,
  EditorialCards: EditorialCards,
  FeaturedArticles: FeaturedArticles,
  FeaturedCardsCarousel: FeaturedCards,
  HelpTitleText: HelpTitleText,
  HeroTabulatedCarousel: HeroTabulatedBanner,
  ImageWithLink: ImageWithLinkSection,
  KitchenList: KitchenList,
  LoginRationale: LoginInfoPanel,
  LogoCarousel: LogoCarousel,
  MKMVideo: Video,
  MKMIframe: IframeContainer,
  MarketingBannerCombined: MarketingBanner,
  PatternListComponent: PatternList,
  RegistrationRationale: CreateAccount,
  TextSearchResults: SearchResults,
  USPBlock: PageBlocks,
  USPHeaderBar: HeaderBar,
  VsfEditorial: EditorialBlock,
  "Vsf Gallery": Gallery,
  footer: Footer,
  imageWithText: ImageWithTextSection,
  menu: Header,
  responsiveImageContainer: ImageContainer,
  shopByRoomMenu: Menu,
  social: FooterSocialLinks,
  "tc-menu": FooterTermsMenu,
  yourMkmGuestMenu: HeaderGuestMenu,
  PromoTilesComponent: PromotionalTiles,
  [TYPE_CONTAINER_ITEM_UNDEFINED]: "",
};

const useBloomreach = () => {
  const route = useRoute();
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  interface PageContent extends Page {
    root?: Record<string, any>;
  }

  interface Visitor {
    id: string | null;
    header: string | null; // An HTTP-header using to pass the visitor id to the Page Model API.
    new?: boolean | null;
  }

  const content = useState<Page | Ref<PageContent> | null>(`content`, () => null);
  const visitor = useState<Visitor | null>("visitor", () => null);
  const isNavigating = useState<boolean>("navigating", () => false);

  const configuration: Configuration = reactive({
    endpoint: route.query.endpoint || `${config.public.BLOOMREACH_CMS_ENDPOINT}/pages`,
    httpClient: axios,
    path: route.fullPath,
    NBRMode: true,
  });

  const containsPageNotFound = (obj: any): boolean => {
    if (obj?.name === "page-not-found") {
      return true;
    }
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null && containsPageNotFound(obj[key])) {
        return true;
      }
    }
    return false;
  };

  async function initialize() {
    const event = useRequestEvent();
    const start = performance.now();

    if (!checkIfPageExistInBR(route)) return;

    const { data: pageData } = await useAsyncData(async (context) => {
      const page = await initializeSdk({
        ...configuration,
        httpClient: axios,
        visitor: undefined,
        request: context?.ssrContext?.event?.node?.req,
      });

      return { page: page.toJSON() };
    });

    if (containsPageNotFound(pageData.value) && !route.path.includes("/explore")) {
      content.value = null;
      throw createError({
        statusCode: 404,
        message: "page not found",
        statusMessage: "no page",
        fatal: false,
      });
    }

    content.value = pageData.value?.page ?? null;

    const end = performance.now(); // End timing
    const duration = end - start;

    const existingHeader = event && event.node.res.getHeader("Server-Timing");
    const newTiming = `bloomreachContent;dur=${duration}`;

    // Append new timing to existing header or set new header
    if (existingHeader) {
      event.node.res.setHeader("Server-Timing", `${existingHeader}, ${newTiming}`);
    } else {
      event && event.node.res.setHeader("Server-Timing", newTiming);
    }
  }

  watch(
    () => route.fullPath,
    async (newPath, oldPath) => {
      if (
        (route.path.includes("/category") && route.params.cat2?.length > 0) ||
        (route.path.includes("/category") && route.params.cat3?.length > 0) ||
        route.path.includes("/product") ||
        route.path.includes("/explore")
      ) {
        return;
      }

      const isPaginationChange =
        newPath.split("?")[0] === oldPath?.split("?")[0] && (newPath.includes("page=") || oldPath?.includes("page="));

      await nextTick(async () => {
        content.value = null;
        configuration.path = route.fullPath;

        nuxtApp.hook("page:start", () => {
          isNavigating.value = true;
        });

        nuxtApp.hook("page:finish", () => {
          isNavigating.value = false;
        });

        if (isPaginationChange) {
          await initialize();
        }
      });
    },
  );

  return {
    initialize,
    content,
    configuration,
    componentMapping,
    isNavigating,
  };
};

export default useBloomreach;
